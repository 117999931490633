import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OurRoutes from './Routes';
import * as Routers from './Routers';
import SkeletonUi from './fallbackloader';

function AppRouter() {
  return (
    <Router>
      {/* <SkeletonUi/> */}
      <Suspense fallback={<SkeletonUi/>} >

        <Routes>
          {/* <Route path={OurRoutes.SIDENAV} element={<Routers.SIDENAV />} /> */}

          <Route path={OurRoutes.LOGIN} element={<Routers.LOGIN />} />
          <Route path={OurRoutes.FORGOTPASSWORD} element={<Routers.FORGOTPASSWORD />} />
          <Route path={OurRoutes.RESETPASSWORD} element={<Routers.RESETPASSWORD />} />

          <Route element={<Routers.MainLayout />} >
            <Route path={OurRoutes.LANDINGPAGE} element={<Routers.LANDINGPAGE />} />
            <Route path={OurRoutes.RULE_CONFIG} element={<Routers.RULE_CONFIG />} />
            <Route path={OurRoutes.RULE_LISTING} element={<Routers.RULE_LISTING />} />
            <Route path={OurRoutes.RULE_ALLOCATION} element={<Routers.RULE_ALLOCATION />} />
            <Route path={OurRoutes.AGENT_ONBOARD_FORM} element={<Routers.AGENT_ONBOARD_FORM />} />
            <Route path={OurRoutes.AGENT_LISTING} element={<Routers.AGENT_LISTING />} />
            <Route path={OurRoutes.LM_CONFIG} element={<Routers.LM_CONFIG />} />

            <Route path={OurRoutes.LEAD_ALLOCATION} element={<Routers.LEAD_ALLOCATION_VIA_RULES />} />
            <Route path={OurRoutes.LEAD_ALLOCATION_CSV} element={<Routers.LEAD_ALLOCATION_VIA_CSV />} />
            <Route path={OurRoutes.ALLOCATION_LISTING} element={<Routers.ALLOCATION_LISTING />} />
            <Route path={OurRoutes.MANUAL_ALLOCATION} element={<Routers.MANUAL_ALLOCATION />} />
            <Route path={OurRoutes.EDIT_AGENT_RULES} element={<Routers.EDIT_AGENT_RULES />} />
            <Route path={OurRoutes.DEBTOR_ONBOARD} element={<Routers.DEBTOR_ONBOARD />} />
            <Route path={OurRoutes.DEBTOR_LISTING} element={<Routers.DEBTOR_LISTING />} />
            <Route path={OurRoutes.DEBTOR_DETAILS} element={<Routers.DEBTOR_DETAILS />} />
            <Route path={OurRoutes.DAILY_COLLECTION_REPORT} element={<Routers.DAILY_COLLECTION_REPORT />} />

            <Route path={OurRoutes.PERF_TRACK} element={<Routers.PERF_TRACK />} />
            <Route path={OurRoutes.COLLECTION_SCHEDULED_ACHEIVED} element={<Routers.COLLECTION_SCHEDULED_ACHEIVED />} />
            <Route path={OurRoutes.TOTAL_DUES_ANALYSIS} element={<Routers.TOTAL_DUES_ANALYSIS />} />
            <Route path={OurRoutes.PROFILE} element={<Routers.USER_PROFILE />} />
            <Route path={OurRoutes.LM_REPORTS} element={<Routers.LM_REPORTS />} />
            <Route path={OurRoutes.REPORTING_DASHBOARD} element={<Routers.REPORTING_DASHBOARD />} />
            <Route path={OurRoutes.REPORTING_DASHBOARD2} element={<Routers.REPORTING_DASHBOARD2 />} />
            {/* <Route path={OurRoutes.PROFILE} element={<Routers.PROFILE/>} /> */}

            <Route path={OurRoutes.ONBOARD_EMP} element={<Routers.ONBOARD_EMP />} />
            <Route path={OurRoutes.EMP_LISTING} element={<Routers.EMP_LISTING />} />
            <Route path={OurRoutes.ORG_LISTING} element={<Routers.ORG_LISTING />} />
            <Route path={OurRoutes.ONBOARD_ORG} element={<Routers.ONBOARD_ORG />} />
            <Route path={OurRoutes.ACCESS_MGMT} element={<Routers.ACCESS_MGMT />} />
            <Route path={OurRoutes.BULK_COMMUNICATION} element={<Routers.BULK_COMMUNICATION />} />
            <Route path={OurRoutes.COMMENTS_CONFIG} element={<Routers.COMMENTS_CONFIG />} />
            <Route path={OurRoutes.COMMUNICATION_CONFIG} element={<Routers.COMMUNICATION_CONFIG />} />
            <Route path={OurRoutes.AUTOMATION_CONFIG} element={<Routers.AUTOMATION_CONFIG />} />
            <Route path={OurRoutes.EMAILID_CONFIG} element={<Routers.EMAILID_CONFIG />} />
            <Route path={OurRoutes.ALL_ACTIVITIES} element={<Routers.ALL_ACTIVITIES />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}
export default AppRouter
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';
import AppRouter from './layout/App.Router';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderApp = () => {
  root.render(
    <React.StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <AppRouter />
      </Suspense>
    </React.StrictMode>
  );
};

// Dynamic import lodash
import('lodash').then((lodash) => {
  // Now you can use lodash here
  console.log('Lodash version:', lodash.VERSION);
  renderApp();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

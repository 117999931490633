import React from "react";

const SkeletonUi = () => {
    return (
        <div className="background-blur">
            <div
                className="position-relative "
                style={{ minHeight: "100%"}}
            >
                <div
                    className="position-absolute"
                    style={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                   <h4>Loading...</h4>
                </div>
            </div>
        </div>
    )
}
export default SkeletonUi
import React from 'react';

export const LOGIN = React.lazy(() => import('../components/Access/login.js'));
export const FORGOTPASSWORD = React.lazy(() => import('../components/Access/forgotPassword.js'));
export const RESETPASSWORD = React.lazy(() => import('../components/Access/resetPassword.js'));
export const RULE_CONFIG = React.lazy(() => import('../components/LeadManagement/RuleConfiguration/ruleConfig.js'));
export const RULE_LISTING = React.lazy(() => import('../components/LeadManagement/RuleConfiguration/ruleListing.js'));
export const RULE_ALLOCATION = React.lazy(() => import('../components/LeadManagement/LeadAllocation/allocationViaRules.js'));
export const SIDENAV = React.lazy(() => import('../components/Layouting/Dashboard.js'));
export const LANDINGPAGE = React.lazy(() => import('../components/Layouting/landingPage.js'));
export const MainLayout = React.lazy(() => import('../components/Layouting/Dashboard.js'));
export const AGENT_LISTING = React.lazy(() => import('../components/AgentOnBoarding/agentListing.js'));
export const LM_CONFIG = React.lazy(() => import('../components/Configuration/LeadMgmtConfig.js'));
export const AGENT_ONBOARD_FORM = React.lazy(() => import('../components/AgentOnBoarding/onboardForm.js'));
export const LEAD_ALLOCATION_VIA_RULES = React.lazy(() => import('../components/LeadManagement/LeadAllocation/allocationViaRules.js'));
export const LEAD_ALLOCATION_VIA_CSV = React.lazy(() => import('../components/LeadManagement/LeadAllocation/allocationViaCsv.js'));
export const ALLOCATION_LISTING = React.lazy(() => import('../components/LeadManagement/LeadAllocation/allocationListing.js'));
export const MANUAL_ALLOCATION = React.lazy(() => import('../components/LeadManagement/LeadAllocation/manualAllocation.js'));
export const EDIT_AGENT_RULES = React.lazy(() => import('../components/LeadManagement/LeadAllocation/editRulesAgent.js'));
export const DEBTOR_LISTING = React.lazy(() => import('../components/DebtorsHub/debtorListing.js'));
export const DEBTOR_DETAILS = React.lazy(() => import('../components/DebtorsHub/debtorDetailsMain.js'));
export const DAILY_COLLECTION_REPORT = React.lazy(() => import('../components/Reports/DailyCollection.js'));
export const PERF_TRACK = React.lazy(() => import('../components/Reports/PerformanceTracker.js'));
export const COLLECTION_SCHEDULED_ACHEIVED = React.lazy(() => import('../components/Reports/CollectionScheduleAcheived.js'));
export const TOTAL_DUES_ANALYSIS = React.lazy(() => import('../components/Reports/CollectionDueAnalysis.js'));
export const USER_PROFILE = React.lazy(() => import('../components/Dashboard/MyProfile.js'));
export const LM_REPORTS = React.lazy(() => import('../components/Dashboard/LMReport.js'));
export const REPORTING_DASHBOARD = React.lazy(() => import('../components/Reports/Dashboardreporting.js'));
export const REPORTING_DASHBOARD2 = React.lazy(() => import('../components/Dashboard/Dashboard.js'));

export const DEBTOR_ONBOARD = React.lazy(() => import('../components/DebtorsHub/addDebtors.js'));
export const ONBOARD_EMP = React.lazy(() => import('../components/UsersHub/EmployeeHub/onboardForm.js'));
export const EMP_LISTING = React.lazy(() => import('../components/UsersHub/EmployeeHub/employeeListing.js'));
export const ORG_LISTING = React.lazy(() => import('../components/OrganizationHub/Organizationlisting.js'));
export const ONBOARD_ORG = React.lazy(() => import('../components/OrganizationHub/OnboardOrgForm.js'));
export const ACCESS_MGMT = React.lazy(() => import('../components/UsersHub/Access Management/AdminRoleMngmnt.js'));
export const BULK_COMMUNICATION = React.lazy(() => import('../components/DebtorsHub/BulkCommunication/BulkCommunicationMain.js'));
export const COMMENTS_CONFIG = React.lazy(() => import('../components/Configuration/configureComments.js'))
export const COMMUNICATION_CONFIG = React.lazy(() => import('../components/Configuration/ConfigureCommunicationTemplates/configTemplateMain.js'))
export const AUTOMATION_CONFIG = React.lazy(() => import('../components/Configuration/ConfigureAutomation/configureAutomationMain.js'))
export const EMAILID_CONFIG = React.lazy(() => import('../components/Configuration/configureEmailId.js'))
export const ALL_ACTIVITIES = React.lazy(() => import('../components/Reports/Allactivites/AllActivities.js'))

const routes = {
    LOGIN: '/',
    FORGOTPASSWORD: '/forgotpassword',
    RESETPASSWORD: '/resetpass/*',
    RULE_CONFIG: '/rulesconfig',
    RULE_LISTING: '/ruleslisting',
    RULE_ALLOCATION: '/ruleallocation',
    SIDENAV: '/sidenav',
    LANDINGPAGE: '/landingPage',
    AGENT_ONBOARD_FORM: '/onboardAgent',
    AGENT_LISTING: '/agentList',
    EDIT_AGENT_RULES:'editAgentRules',
    LM_CONFIG: '/lmConfig',
    DEBTOR_ONBOARD: '/onboardDebtor',
    DEBTOR_LISTING: '/debtorList',
    DEBTOR_DETAILS: '/debtorDetails',
    BULK_COMMUNICATION: '/bulkCommunicate',
    COMMUNICATION_CONFIG: '/configTemplates',
    COMMENTS_CONFIG: '/configComments',
    AUTOMATION_CONFIG: '/configAutomation',
    EMAILID_CONFIG: '/configEmailId',
    // PAYMENT_CONFIG: '/configPayment',
    DAILY_COLLECTION_REPORT: '/dailyCollectionReport',
    COLLECTION_SCHEDULED_ACHEIVED: '/collectionScheduled&Achieved',
    PERF_TRACK: '/performanceTracker',
    TOTAL_DUES_ANALYSIS: '/totalDuesAnalysis',
    // PROGRESS_EFF_REPORT: '/prrogressEfficiencyReport',
    // STATE_OF_BUSINESS: '/stateOfBusiness',
    // RECENT_ACTIVITIES: ''
    // ACCOUNT_RECONC: ''
    ALL_ACTIVITIES: '/allactivities',

    ONBOARD_EMP: '/onboardEmployee',
    EMP_LISTING: '/employeeListing',
    ACCESS_MGMT: '/accessMgmt',
    PROFILE: '/MyProfile',
    ORG_LISTING: '/organizationListing',
    ONBOARD_ORG: '/onboardOrganization',
    // ALL_MAILS: ''
    // NEW_TICKETS: ''
    // TICKET_LOGS: ''
    LM_REPORTS: '/lmReport',
    LEAD_ALLOCATION: '/leadAllocation',
    LEAD_ALLOCATION_CSV: '/leadAllocationCsv',
    ALLOCATION_LISTING: '/allocationListing',
    MANUAL_ALLOCATION : '/manualAllocation',
    REPORTING_DASHBOARD : '/ReportingDashboard',
    REPORTING_DASHBOARD2 : '/ReportingDashboard2',
}
export default routes;